<template>
    <div class="tenadntOrderList">
        <div class="headBox">
            <div>
                <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="back()">返回</el-button>
            </div>
        </div>
        <table-list
            :ButtonWidth="140"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
              
            </template>
            <template #Button="scope">
            </template>
        </table-list>

        
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import businessConfigureApi from "../../api/businessConfigureApi";
export default {
    name: "tenadntOrderList",
    components: { TableList },
    data() {
        return {
            tableData: [
                {
                    id:'汇总',
                    signingAmount:30000
                },
                {
                    id:1,
                    signingAmount:10000
                },
                {
                    id:2,
                    signingAmount:10000
                },
                {
                    id:3,
                    signingAmount:10000
                },
            ],
            colrs: [
                { label: "订单号", prop: "id", width: 230 },
                { label: "客户姓名", prop: "customerName", width: 100 },
                { label: "金额(元)", prop: "signingAmount", width: 100 },
                { label: "支付平台", prop: "paymentPlatformType", width: 100 },
                { label: "签约状态", prop: "signingStatus", width: 100 },
                { label: "签约类型", prop: "signingType", width: 100 },
                { label: "订单状态", prop: "repaymentStatusDesc", width: 120 },
                { label: "签约日期", prop: "signingCompleteTime", width: 180 },
                { label: "订单完成日期", prop: "settlementCompleteTime", width: 180 },
                { label: "解约日期", prop: "settlementCompleteTime", width: 180 },
                { label: "结算类型", prop: "settlementType", width: 100 },
                { label: "", prop: "", width: "" }
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,
          
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
           
        },

       
        back() {
            this.$router.back();
        },
       

        currentChange(pageIndex) {
            this.pageIndex = pageIndex;
        },
        handleSizeChange(pagesize) {
            this.pageIndex = 1;
            this.pagesize = pagesize;
        }
    }
};
</script>
<style lang="scss" scoped>
.tenadntOrderList {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    height: 89vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            .select {
                display: flex;
                align-items: center;
                .selectTit {
                    background-color: #f5f7fa;
                    color: #909399;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #dcdfe6;
                    border-right: 0;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    height: 26px;
                    line-height: 26px;
                    white-space: nowrap;
                }
                .el-select {
                    width: 180px;
                    /deep/.el-input__inner {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }
    .box-card {
        // width: 600px;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
    }
    .userInfo_title {
        font-size: 16px;
        color: #222222;
        margin-bottom: 40px;
        font-weight: 700;
    }
    .zhBialog {
        /deep/.el-dialog__header,
        /deep/.el-dialog__body {
            padding: 0;
        }
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }
}
</style>
